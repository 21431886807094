/* service */
.section-service-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('/src/assets/Home/Service/service_bg.jpg') no-repeat fixed;
  background-size: cover;
  min-height: 60vh !important;
  padding: 10vh 0vh 10vh 0vh;
  position: relative;
}

.service-container {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
}

.service-card {
  background: url('/src/assets/Home/Service/card_bg.png');
  background-size: auto 280px;
  background-repeat: no-repeat;
  background-position: center;
  height: 300px;
  flex: 0 0 300px;
  /*not growable (0), not shrinkable (0), and with an initial length of 200 pixels*/
}

.service-container .service-card .desain-ui-ux {
  background: url('/src/assets/Home/Service/desain_ui_ux.png');
  background-position: center;
  background-size: auto 100px;
  background-repeat: no-repeat;
  height: 100px;
  width: 100%;
  margin-top: 80px;
}

.service-container .service-card .integrasi-software-hardware {
  background: url('/src/assets/Home/Service/integrasi_software_hardware.png');
  background-position: center;
  background-size: auto 100px;
  background-repeat: no-repeat;
  height: 100px;
  width: 100%;
  margin-top: 80px;
}

.service-container .service-card .it-support-penuh {
  background: url('/src/assets/Home/Service/it_support_penuh.png');
  background-position: center;
  background-size: auto 100px;
  background-repeat: no-repeat;
  height: 100px;
  width: 100%;
  margin-top: 80px;
}

.service-container .service-card .kecerdasan-buatan {
  background: url('/src/assets/Home/Service/kecerdasan_buatan.png');
  background-position: center;
  background-size: auto 100px;
  background-repeat: no-repeat;
  height: 100px;
  width: 100%;
  margin-top: 80px;
}

.service-container .service-card .konsultasi-sistem-digital {
  background: url('/src/assets/Home/Service/konsultasi_sistem_digital.png');
  background-position: center;
  background-size: auto 100px;
  background-repeat: no-repeat;
  height: 100px;
  width: 100%;
  margin-top: 80px;
}

.service-container .service-card .pengembangan-aplikasi {
  background: url('/src/assets/Home/Service/pengembangan_aplikasi.png');
  background-position: center;
  background-size: auto 100px;
  background-repeat: no-repeat;
  height: 100px;
  width: 100%;
  margin-top: 80px;
}

.service-container .service-card .pengembangan-infrastruktur {
  background: url('/src/assets/Home/Service/pengembangan_infrastruktur.png');
  background-position: center;
  background-size: auto 100px;
  background-repeat: no-repeat;
  height: 100px;
  width: 100%;
  margin-top: 80px;
}

.service-container .service-card .pengembangan-web {
  background: url('/src/assets/Home/Service/pengembangan_web.png');
  background-position: center;
  background-size: auto 100px;
  background-repeat: no-repeat;
  height: 100px;
  width: 100%;
  margin-top: 80px;
}

.service-container .service-card .item-text {
  text-align: center;
  margin-top: 0.5rem;
}

.service-container .service-card .item-text p.title {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  margin-bottom: 0.1rem;
}

.service-container .service-card .item-text p.subtitle {
  color: white;
  font-weight: 400;
  margin-bottom: 0.1rem;
}

.section-service-bg .text {
  text-align: end;
  flex-grow: 1;
  margin-right: 48px;
}

.section-service-bg .text>h1 {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.section-service-bg .text>p {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
}

@media screen and (max-width: 322px) {
  .service-card {
    background: url('/src/assets/Home/Service/card_bg.png');
    background-size: auto 110px;
    background-repeat: no-repeat;
    background-position: center;
    height: 120px;
    flex: 0 0 120px;
    /*not growable (0), not shrinkable (0), and with an initial length of 200 pixels*/
  }

  .service-container .service-card .desain-ui-ux {
    background: url('/src/assets/Home/Service/desain_ui_ux.png');
    background-position: center;
    background-size: auto 50px;
    background-repeat: no-repeat;
    height: 50px;
    width: 100%;
    margin-top: 20px;
  }

  .service-container .service-card .integrasi-software-hardware {
    background: url('/src/assets/Home/Service/integrasi_software_hardware.png');
    background-position: center;
    background-size: auto 50px;
    background-repeat: no-repeat;
    height: 50px;
    width: 100%;
    margin-top: 20px;
  }

  .service-container .service-card .it-support-penuh {
    background: url('/src/assets/Home/Service/it_support_penuh.png');
    background-position: center;
    background-size: auto 50px;
    background-repeat: no-repeat;
    height: 50px;
    width: 100%;
    margin-top: 20px;
  }

  .service-container .service-card .kecerdasan-buatan {
    background: url('/src/assets/Home/Service/kecerdasan_buatan.png');
    background-position: center;
    background-size: auto 50px;
    background-repeat: no-repeat;
    height: 50px;
    width: 100%;
    margin-top: 20px;
  }

  .service-container .service-card .konsultasi-sistem-digital {
    background: url('/src/assets/Home/Service/konsultasi_sistem_digital.png');
    background-position: center;
    background-size: auto 50px;
    background-repeat: no-repeat;
    height: 50px;
    width: 100%;
    margin-top: 20px;
  }

  .service-container .service-card .pengembangan-aplikasi {
    background: url('/src/assets/Home/Service/pengembangan_aplikasi.png');
    background-position: center;
    background-size: auto 50px;
    background-repeat: no-repeat;
    height: 50px;
    width: 100%;
    margin-top: 20px;
  }

  .service-container .service-card .pengembangan-infrastruktur {
    background: url('/src/assets/Home/Service/pengembangan_infrastruktur.png');
    background-position: center;
    background-size: auto 50px;
    background-repeat: no-repeat;
    height: 50px;
    width: 100%;
    margin-top: 20px;
  }

  .service-container .service-card .pengembangan-web {
    background: url('/src/assets/Home/Service/pengembangan_web.png');
    background-position: center;
    background-size: auto 50px;
    background-repeat: no-repeat;
    height: 50px;
    width: 100%;
    margin-top: 20px;
  }

  .service-container .service-card .item-text {
    text-align: center;
    margin-top: 0.5rem;
  }

  .service-container .service-card .item-text p.title {
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 0.5em;
    margin-bottom: 0.1rem;
  }

  .service-container .service-card .item-text p.subtitle {
    color: white;
    font-weight: 400;
    font-size: 0.5em;
    margin-bottom: 0.1rem;
  }

  .section-service-bg .text {
    text-align: end;
    flex-grow: 1;
    margin-right: 48px;
  }

  .section-service-bg .text>h1 {
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
  }

  .section-service-bg .text>p {
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
  }
}

@media screen and (min-width: 322px) and (max-width: 767px) {
  .service-card {
    background: url('/src/assets/Home/Service/card_bg.png');
    background-size: auto 110px;
    background-repeat: no-repeat;
    background-position: center;
    height: 120px;
    flex: 0 0 120px;
    /*not growable (0), not shrinkable (0), and with an initial length of 200 pixels*/
  }

  .service-container .service-card .desain-ui-ux {
    background: url('/src/assets/Home/Service/desain_ui_ux.png');
    background-position: center;
    background-size: auto 50px;
    background-repeat: no-repeat;
    height: 50px;
    width: 100%;
    margin-top: 20px;
  }

  .service-container .service-card .integrasi-software-hardware {
    background: url('/src/assets/Home/Service/integrasi_software_hardware.png');
    background-position: center;
    background-size: auto 50px;
    background-repeat: no-repeat;
    height: 50px;
    width: 100%;
    margin-top: 20px;
  }

  .service-container .service-card .it-support-penuh {
    background: url('/src/assets/Home/Service/it_support_penuh.png');
    background-position: center;
    background-size: auto 50px;
    background-repeat: no-repeat;
    height: 50px;
    width: 100%;
    margin-top: 20px;
  }

  .service-container .service-card .kecerdasan-buatan {
    background: url('/src/assets/Home/Service/kecerdasan_buatan.png');
    background-position: center;
    background-size: auto 50px;
    background-repeat: no-repeat;
    height: 50px;
    width: 100%;
    margin-top: 20px;
  }

  .service-container .service-card .konsultasi-sistem-digital {
    background: url('/src/assets/Home/Service/konsultasi_sistem_digital.png');
    background-position: center;
    background-size: auto 50px;
    background-repeat: no-repeat;
    height: 50px;
    width: 100%;
    margin-top: 20px;
  }

  .service-container .service-card .pengembangan-aplikasi {
    background: url('/src/assets/Home/Service/pengembangan_aplikasi.png');
    background-position: center;
    background-size: auto 50px;
    background-repeat: no-repeat;
    height: 50px;
    width: 100%;
    margin-top: 20px;
  }

  .service-container .service-card .pengembangan-infrastruktur {
    background: url('/src/assets/Home/Service/pengembangan_infrastruktur.png');
    background-position: center;
    background-size: auto 50px;
    background-repeat: no-repeat;
    height: 50px;
    width: 100%;
    margin-top: 20px;
  }

  .service-container .service-card .pengembangan-web {
    background: url('/src/assets/Home/Service/pengembangan_web.png');
    background-position: center;
    background-size: auto 50px;
    background-repeat: no-repeat;
    height: 50px;
    width: 100%;
    margin-top: 20px;
  }

  .service-container .service-card .item-text {
    text-align: center;
    margin-top: 0.5rem;
  }

  .service-container .service-card .item-text p.title {
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 0.5em;
    margin-bottom: 0.1rem;
  }

  .service-container .service-card .item-text p.subtitle {
    color: white;
    font-weight: 400;
    font-size: 0.5em;
    margin-bottom: 0.1rem;
  }

  .section-service-bg .text {
    text-align: end;
    flex-grow: 1;
    margin-right: 48px;
  }

  .section-service-bg .text>h1 {
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
  }

  .section-service-bg .text>p {
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
  }
}