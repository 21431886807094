.section-alur-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../../../assets/Home/contact_bg.jpg') no-repeat fixed;
  background-size: cover;
  min-height: 60vh !important;
  padding: 10vh 0vh 10vh 0vh;
  position: relative;
}

.section-alur-bg h1 {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.section-alur-bg p {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
}

.section-alur-bg .section-contact-button-list {
  margin: 0 auto;
  margin-top: 10vh;
  text-align: center;
}

.section-alur-bg .section-whatsapp {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 50vh;
}

.flex-alur {
  display: flex;
  justify-content: center;
}

.alur-image {
  width: 1296px;
  overflow-x: scroll;
}

.alur-image::-webkit-scrollbar-track {
  border: 0px solid #000;
  padding: 2px 0;
  background-color: transparent;
}

.alur-image::-webkit-scrollbar {
  width: 10px;
}

.alur-image::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #fff;
  border: 0px solid #000;
}

.alur-image>.image {
  background-image: url("../../../assets/Home/Alur/alur.svg");
  background-repeat: no-repeat;
  background-size: auto 178px;
  height: 200px;
  width: 1296px;
}

@media screen and (max-width: 322px) {
  .section-alur-bg {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url('../../../assets/Home/contact_bg.jpg') no-repeat fixed;
    background-size: cover;
    min-height: 60vh !important;
    padding: 10vh 0vh 10vh 0vh;
    position: relative;
  }

  .section-alur-bg .section-whatsapp {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 90%;
  }
}

@media screen and (min-width: 322px) and (max-width: 767px) {
  .section-alur-bg {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url('../../../assets/Home/contact_bg.jpg') no-repeat fixed;
    background-size: cover;
    min-height: 60vh !important;
    padding: 10vh 0vh 10vh 0vh;
    position: relative;
  }

  .section-alur-bg .section-whatsapp {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 90%;
  }
}