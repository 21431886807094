.section-visi {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../../assets/About/visi_sec1.jpg') no-repeat fixed;
  background-size: cover;
  /* box-shadow: inset 0 0 0 1000px rgba(0,0,0,.2); */
  min-height: 100vh !important;
}

.section-visi .section-about {
  padding-top: 32vh;
  padding-bottom: 20vh;
  padding-left: 5vh;
  padding-right: 5vh;
  display: flex;
  width: 100%;
}

.section-fragment-left {
  flex: 30%;
  text-align: left;
}

.section-fragment-left>h2 {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

.section-fragment-left>h3 {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.section-fragment-left>p {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
}

.section-fragment-left>.ys {
  width: 30%;
  padding-top: 5vh;
}

.section-fragment-left>.ys>.ys-num {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: white;
  font-size: 1.8em;
}

.section-fragment-left>.ys>.ys-txt {
  font-family: 'Roboto', sans-serif;
  font-size: 0.9em;
  color: white;
  font-weight: 100;
}

.section-fragment-mid {
  flex: 40%;
}

.section-fragment-right {
  flex: 30%;
  text-align: right;
}

.section-fragment-right>h2 {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

.section-fragment-right>h3 {
  padding-top: 1.2em;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.section-fragment-right>p.tal-way {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.section-fragment-right>p {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
}

.section-fragment-right>p.hashtag {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.section-share {
  height: 80vh !important;
  background-color: white;
}

h1.head-visi {
  padding-top: 45vh;
  display: block;
  text-align: center;
  color: white;
}

p.p-visi {
  display: block;
  text-align: center;
  color: white;
}

.section-footer {
  position: absolute;
  bottom: 10px;
  padding-left: 5vh;
  padding-right: 5vh;
  display: flex;
  width: 100%;
}

.section-footer>.socmed {
  text-align: left;
  flex: 50%;
}

.section-footer>.socmed>.ig {
  background: url('../../assets/About/ig.png');
  background-repeat: no-repeat;
  background-size: auto 24px;
  display: inline-block;
  width: 24px;
  height: 22px;
  margin-right: 12px;
}

.section-footer>.socmed>.yt {
  background: url('../../assets/About/yt.png');
  background-repeat: no-repeat;
  background-size: auto 24px;
  display: inline-block;
  width: 24px;
  height: 22px;
  margin-right: 12px;
}

.section-footer>.socmed>.in {
  background: url('../../assets/About/in.png');
  background-repeat: no-repeat;
  background-size: auto 24px;
  display: inline-block;
  width: 24px;
  height: 22px;
  margin-right: 12px;
}

.section-footer>.socmed>.tw {
  background: url('../../assets/About/tw.png');
  background-repeat: no-repeat;
  background-size: auto 22px;
  display: inline-block;
  width: 24px;
  height: 22px;
}

.section-footer>.copyright {
  text-align: right;
  flex: 50%;
  color: white;
}

@media screen and (min-width: 322px) and (max-width: 767px) {
  /*untuk mobile*/

}