.why-div {
  margin-top: 8vh;
  height: 100%;
}

.why-list {
  color: white;
  padding: 1em;
  font-size: 1em;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  list-style-position: inside;
}

.why-image-button {
  height: 50px;
  width: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.why-text-button {
  color: white;
  text-align: center;
}

@media screen and (min-width: 322px) and (max-width: 767px) {
  .why-div {
    margin-top: 2vh;
    height: 100%;
  }

  .why-list {
    color: white;
    padding: 0.3em;
    font-size: 0.8em;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    list-style-position: inside;
  }

  .why-image-button {
    height: 40px;
    width: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .why-text-button {
    color: white;
    text-align: center;
    font-size: 0.8em;
  }
}

@media screen and (max-width: 322px) {
  .why-div {
    margin-top: 2vh;
    height: 100%;
  }

  .why-list {
    color: white;
    padding: 0.3em;
    font-size: 0.8em;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    list-style-position: inside;
  }

  .why-image-button {
    height: 40px;
    width: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .why-text-button {
    color: white;
    text-align: center;
    font-size: 0.8em;
  }
}