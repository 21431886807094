.featured-image {
  max-height: 50vh;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: auto;
}

.heading {
  text-align: center;
  color: white;
  font-weight: 700;
  font-size: 36px;
  /* bold roboto*/
  font-family: 'Roboto', sans-serif;
}

.featured-content {
  text-align: center;
  max-width: 80vw;
  margin-left: auto;
  margin-right: auto;
  color: white;
  /* light roboto */
  font-weight: 300;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
}

.flex-c-row-center {
  display: flex;
  justify-content: center;
}

.flex-c-row-mobile {
  /* untuk mobile */
  display: none;
}

.flex-c-col-center {
  display: flex;
  flex-direction: column;
}

.flex-c-col-mobile {
  display: none;
}

.project-properties {
  display: block;
  margin-top: 20px;
  text-align: center;
  width: 40%;
}

.project-properties>.prop-android {
  display: inline-block !important;
  width: 150px !important;
  margin: 0px 2.5px 0px 2.5px;
  text-align: center;
}

.project-properties>.prop-android>.android {
  background-image:
    url('/src/assets/Universe/Props/android.png');
  background-repeat: no-repeat;
  background-size: auto 26px;
  height: 26px;
  width: auto;
}

.project-properties>.prop-android>.text {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 12px;
}


.project-properties>.prop-ios {
  display: inline-block;
  width: 130px;
  margin: 0px 2.5px 0px 2.5px;
  text-align: center;
}

.project-properties>.prop-ios>.ios {
  background:
    url('../../../assets/Universe/Props/web.png');
  background-repeat: no-repeat;
  background-size: auto 26px;
  background-position: center;
  height: 26px;
  width: auto;
}

.project-properties>.prop-ios>.text {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 12px;
}

.project-properties>.prop-year-made {
  display: inline-block;
  width: 120px;
  margin: 0px 2.5px 0px 2.5px;
  text-align: center;
}

.project-properties>.prop-year-made>.year {
  font-size: 1.5em;
  color: white;
  margin-bottom: 0px;
}

.project-properties>.prop-year-made>.text {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 12px;
}

.project-properties>.prop-access {
  display: inline-block;
  width: 120px;
  margin: 0px 2.5px 0px 2.5px;
  text-align: center;
}

.project-properties>.prop-access>.website {
  background:
    url('../../../assets/Universe/Props/web.png');
  background-repeat: no-repeat;
  background-size: auto 26px;
  background-position: center;
  width: auto;
  height: 26px;
  margin-top: 2px;
}

.project-properties>.prop-access>.text {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 12px;
}

.cek-layanan {
  margin-top: 20px;
  width: 230px;
  height: 50px;
  border-radius: 30px;
  border-style: solid;
  border-width: 2px;
  border-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cek-layanan:hover {
  background-color: white;
}

.cek-layanan>a.text {
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cek-layanan>a.text:link {
  color: white;
  text-decoration: none;
}

.cek-layanan>a.text:visited {
  color: white;
  text-decoration: none;
}

.cek-layanan>a.text:active {
  color: white;
  text-decoration: none;
}

.cek-layanan>a.text:hover {
  color: black;
  text-decoration: none;
}

.company-class {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 500;
}

/**
media query
*/
@media screen and (max-width: 992px) {

  /* on larger screen like tablets, 26-06-2022 */
  .flex-c-row-mobile {
    /* untuk mobile */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flex-c-row-center {
    display: none;
  }

  .flex-c-col-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .flex-c-row-mobile>.project-properties {
    display: flex;
    margin-top: 20px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%
  }

  .project-properties>.prop-android {
    display: inline-block !important;
    margin: 0px 2.5px 0px 2.5px;
    width: 120px !important;
    text-align: center;
  }

  .project-properties>.prop-android>.android {
    background-image:
      url('/src/assets/Universe/Props/android.png');
    background-repeat: no-repeat;
    background-size: auto 20px;
    height: 20px;
    width: auto;
  }

  .project-properties>.prop-android>.text {
    color: white;
    font-size: small;
  }

  .project-properties>.prop-ios {
    display: inline-block;
    margin: 0px 2.5px 0px 2.5px;
    width: 105px !important;
    text-align: center;
  }

  .project-properties>.prop-ios>.ios {
    background:
      url('../../../assets/Universe/Props/web.png');
    background-repeat: no-repeat;
    background-size: auto 20px;
    background-position: center;
    height: 20px;
    width: auto;
  }

  .project-properties>.prop-ios>.text {
    color: white;
    font-size: small;
  }

  .project-properties>.prop-year-made>.year {
    font-size: 1.2em;
    color: white;
    margin-bottom: 0px;
  }

  .project-properties>.prop-year-made {
    display: inline-block;
    width: 90px;
    margin: 0px 2.5px 0px 2.5px;
    text-align: center;
  }

  .project-properties>.prop-year-made>.text {
    color: white;
    font-size: small;
  }

  .project-properties>.prop-access>.website {
    background:
      url('../../../assets/Universe/Props/web.png');
    background-repeat: no-repeat;
    background-size: auto 24px;
    background-position: center;
    width: auto;
    height: 22px;
    margin-top: 2px;
  }

  .project-properties>.prop-access {
    display: inline-block;
    width: 90px;
    margin: 0px 2.5px 0px 2.5px;
    text-align: center;
  }

  .project-properties>.prop-access>.text {
    color: white;
    font-size: small;
  }

  .featured-image {
    max-height: 40vh;
    max-width: 90vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .heading {
    text-align: center;
    color: white;
    font-weight: 700;
    font-size: 32px;
    /* bold roboto*/
    font-family: 'Roboto', sans-serif;
  }

  .featured-content {
    text-align: center;
    max-width: 90vw;
    margin-left: auto;
    margin-right: auto;
    color: white;
    /* light roboto */
    font-weight: 300;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
  }

  .company-class {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
  }
}

@media screen and (max-width: 767px) {

  /* on mobile phone, 26-06-2022 */
  .flex-c-row-mobile {
    /* untuk mobile */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flex-c-row-center {
    display: none;
  }

  .flex-c-row-mobile>.project-properties {
    display: flex;
    margin-top: 20px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%
  }

  .flex-c-col-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .project-properties>.prop-android {
    display: inline-block !important;
    margin: 0px 2.5px 0px 2.5px;
    width: 87px !important;
    text-align: center;
  }

  .project-properties>.prop-android>.android {
    background-image:
      url('/src/assets/Universe/Props/android.png');
    background-repeat: no-repeat;
    background-size: auto 15px;
    height: 15px;
    width: auto;
  }

  .project-properties>.prop-android>.text {
    color: white;
    font-size: small;
  }

  .project-properties>.prop-ios {
    display: inline-block;
    margin: 0px 2.5px 0px 2.5px;
    width: 75px !important;
    text-align: center;
  }

  .project-properties>.prop-ios>.ios {
    background:
      url('../../../assets/Universe/Props/web.png');
    background-repeat: no-repeat;
    background-size: auto 15px;
    background-position: center;
    height: 15px;
    width: auto;
  }

  .project-properties>.prop-ios>.text {
    color: white;
    font-size: small;
  }

  .project-properties>.prop-year-made>.year {
    font-size: 1.2em;
    color: white;
    margin-bottom: 0px;
  }

  .project-properties>.prop-year-made {
    display: inline-block;
    width: 90px;
    margin: 0px 2.5px 0px 2.5px;
    text-align: center;
  }

  .project-properties>.prop-year-made>.text {
    color: white;
    font-size: small;
  }

  .project-properties>.prop-access>.website {
    background:
      url('/src/assets/Universe/Props/web.png');
    background-repeat: no-repeat;
    background-size: auto 22px;
    background-position: center;
    width: auto;
    height: 22px;
    margin-top: 2px;
  }

  .project-properties>.prop-access {
    display: inline-block;
    width: 90px;
    margin: 0px 2.5px 0px 2.5px;
    text-align: center;
  }

  .project-properties>.prop-access>.text {
    color: white;
    font-size: small;
  }

  .featured-image {
    max-height: 40vh;
    max-width: 90vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .heading {
    text-align: center;
    color: white;
    font-weight: 700;
    font-size: 28px;
    /* bold roboto*/
    font-family: 'Roboto', sans-serif;
  }

  .featured-content {
    text-align: center;
    max-width: 90vw;
    margin-left: auto;
    margin-right: auto;
    color: white;
    /* light roboto */
    font-weight: 300;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
  }

  .company-class {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
  }
}