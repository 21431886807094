.section-main {
  /* background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  background-size: cover; */
  /* box-shadow: inset 0 0 0 1000px rgba(0,0,0,.2); */
  min-height: 100vh !important;
}

.section-main .section-1 {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding-top: 20vh;
  padding-bottom: 20vh;
  padding-left: 5vh;
  padding-right: 5vh;
}

.section-1 .story {
  flex: 50%;
  text-align: left;
}

.section-1 .img {
  flex: 50%;
  background: url('../../../assets/Services/Developer/uiux_design.png');
  background-position: center;
  background-size: auto 50vh;
  background-repeat: no-repeat;
  height: 50vh;
  width: 100%;
}

.section-1 .story>h3 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;

}

.section-1 .story>p {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.section-main .section-2 {
  background-color: white;
  min-height: 100vh;
}

.section-main .section-2>.section-title {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin-left: 10vw;
  margin-right: 10vw;
}

.section-main .section-2>.section-cards {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
}

.section-main .section-2>.section-cards>.card {
  height: 300px;
  flex: 0 0 300px;
  margin: 10px 10px 10px 10px;
}

.section-main .section-2>.section-cards>.card>.title {
  font-family: 'Roboto', sans-serif;
  font-size: 14;
  font-weight: 700;
  margin: 6px 6px 6px 6px;
}

.section-main .section-2>.section-cards>.card>.deskripsi {
  font-family: 'Roboto', sans-serif;
  font-size: 12;
  margin: 0px 6px 6px 6px;
}


.section-main .section-2>.section-cards>.card>.expertise {
  text-align: center;
}

.section-main .section-2>.section-cards>.card>.client-centric {
  text-align: center;
}

.section-main .section-2>.section-cards>.card>.cutting-edge {
  text-align: center;
}

.section-main .section-2>.section-cards>.card>.quality-assurance {
  text-align: center;
}

.section-main .section-2>.section-cards>.card>.timely-delivery {
  text-align: center;
}

.section-main .section-2>.section-cards>.card>.support-maintenance {
  text-align: center;
}

.section-main .section-2>.section-title>h3 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

.section-main .section-2>.section-title>p {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.btn-konsultasi {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  text-decoration: none;
  max-width: 100px;
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-konsultasi-link {
  text-decoration: none;
  color: inherit;
  display: inline-block;
  text-align: center;
}

.btn-konsultasi:hover {
  transform: translateY(-5px);
}

.section-footer-service {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0px 20px 0px;
  padding: 0vh 5vh 0vh 5vh;
}

.section-footer-service>.footer-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  min-width: 70vw;
  min-height: 100px;
  display: flex;
  flex-direction: row;
}

.footer-card>.left {
  margin: 10px 0px 10px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 2;
}

.footer-card>.left>h2 {
  font-size: 24px;
}

.footer-card>.left>p {
  margin-bottom: 0;
}

.footer-card>.right {
  margin: 10px 40px 10px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
}

.footer-card>.right>.btn-reach {
  align-self: self-end;
}