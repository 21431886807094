.tal-navbar-white {
  background-color: #fff6f6;
}

.tal-logo {
  height: 50px;
}

.tal-navbar-text-white {
  color: #fff6f6;
  font-weight: bold;
  font-size: 1em;
}

.tal-navbar-text-offset {
  color: black;
  font-weight: bold;
  font-size: 1em;
}

.tal-navbar-text-white:link {
  text-decoration: none;
}

.tal-navbar-text-offset:link {
  text-decoration: none;
}