.section-main {
  /* background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  background-size: cover; */
  /* box-shadow: inset 0 0 0 1000px rgba(0,0,0,.2); */
  min-height: 100vh !important;
  padding-top: 120px;
}

.section-main .titles {
  margin: 0px 150px 20px 150px;
}

.message-card {
  margin: 0px 150px 120px 150px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 10px 10px 10px 10px;
}

.message-card .btn-submit {
  margin-top: 10px;

}

@media screen and (max-width: 322px) {
  .section-main .titles {
    margin: 0px 10px 4px 10px;
  }

  .message-card {
    margin: 0px 10px 40px 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
    padding: 10px 10px 10px 10px;
  }
}

@media screen and (min-width: 322px) and (max-width: 767px) {
  .section-main .titles {
    margin: 0px 10px 4px 10px;
  }

  .message-card {
    margin: 0px 10px 40px 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
    padding: 10px 10px 10px 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .section-main .titles {
    margin: 0px 40px 16px 40px;
  }

  .message-card {
    margin: 0px 40px 80px 40px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
    padding: 10px 10px 10px 10px;
  }
}