/* .home-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('/src/assets/Home/home_sec1.jpg') no-repeat fixed;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,.2);
  height: 100vh !important;
} */

/* carousel home (DEPRECATED) */
/* .carousel-one-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('/src/assets/Home/Porto/c_1_haleyora.jpg') no-repeat fixed;
  background-size: cover;
   box-shadow: inset 0 0 0 1000px rgba(0,0,0,.2); 
  height: 100vh !important;
}

.carousel-two-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('/src/assets/Home/Porto/c_2_kollabora.jpg') no-repeat fixed;
  background-size: cover;
   box-shadow: inset 0 0 0 1000px rgba(0,0,0,.2); 
  height: 100vh !important;
} */

/* tidak dipakai lagi, diganti dengan direct styling di HomePage.js */

/* .section-one-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('/src/assets/Home/home_sec1.jpg') no-repeat fixed;
  background-size: cover;
  height: 100vh !important;
} */

.section-why-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../../assets/Home/Why/why.jpg') no-repeat fixed;
  background-size: cover;
  /* box-shadow: inset 0 0 0 1000px rgba(0,0,0,.2); */
  height: 90vh !important;
  position: relative;
}

.section-contact-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../../assets/Home/contact_bg.jpg') no-repeat fixed;
  background-size: cover;
  height: 50vh !important;
  position: relative;
}

.section-why-bg .section-why-button-group {
  width: 100%;
  position: absolute;
  bottom: 8vh;
}

.section-why-bg h1.section-why-title {
  color: white;
  padding-top: 5vh;
  font-family: 'Oswald', sans-serif;
}

.section-contact-bg h1 {
  color: white;
  padding-top: 10vh;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  font-weight: 700;
}

.section-contact-bg .section-contact-button-list {
  margin: 0 auto;
  margin-top: 10vh;
  text-align: center;
}

.section-contact-button-list button {
  margin: 8px;
}

.section-contact-bg .section-whatsapp {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 50vh;
  border-radius: 10px;
  background-color: #FBF8F1;
}

.section-whatsapp>.profile-container {
  padding: 3px 6px 3px 6px;
  display: flex;
  flex-direction: row;
}

.wa-icon {
  background:
    url('/src/assets/Universe/Props/wa.webp');
  background-repeat: no-repeat;
  background-size: auto 20px;
  background-position: center;
  height: 20px;
  width: auto;
}

.section-why-on-mobile {
  display: none;
}

.section-why-on-desktop {
  display: block;
}

.why-image {
  height: auto;
  width: 100%;
  margin-top: 8vh;
}

.why-image-center {
  display: unset;
  align-items: unset;
  justify-content: unset;
}

/**
media query
*/
@media screen and (max-width: 322px) {
  .section-why-on-mobile {
    display: flex;
    flex-direction: column;
  }

  .section-why-on-desktop {
    display: none;
  }

  .section-why-bg {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url('/src/assets/Home/Why/why.jpg') no-repeat fixed;
    background-size: cover;
    height: auto !important;
    /* min-height: 110vh !important; */
    /* box-shadow: inset 0 0 0 1000px rgba(0,0,0,.2); */
  }

  .section-why-bg .section-why-button-group {
    width: 100%;
    position: unset;
    bottom: unset;
  }

  .why-image-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img.why-image {
    text-align: center;
    height: auto;
    width: 68%;
    margin-top: 2vh;
  }

  .section-contact-bg .section-whatsapp {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 90%;
    border-radius: 10px;
    background-color: #FBF8F1;
  }
}

@media screen and (min-width: 322px) and (max-width: 767px) {
  .section-why-on-mobile {
    display: flex;
    flex-direction: column;
  }

  .section-why-on-desktop {
    display: none;
  }

  .section-why-bg {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url('/src/assets/Home/Why/why.jpg') no-repeat fixed;
    background-size: cover;
    height: auto !important;
    /* min-height: 100vh !important; */
    /* box-shadow: inset 0 0 0 1000px rgba(0,0,0,.2); */
  }

  .why-image-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img.why-image {
    text-align: center;
    height: auto;
    width: 68%;
    margin-top: 2vh;
  }

  .section-why-bg .section-why-button-group {
    width: 100%;
    position: unset;
    bottom: unset;
    padding-bottom: 20px;
  }

  .section-contact-bg .section-whatsapp {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 90%;
    border-radius: 10px;
    background-color: #FBF8F1;
  }
}