/*customer*/
.section-customer-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../../../assets/Home/Customer/customer_bg.jpg') no-repeat fixed;
  background-size: cover;
  min-height: 60vh !important;
  padding: 10vh 0vh 10vh 0vh;
  position: relative;
}

.section-customer-bg .section-text {
  text-align: center;
}

.section-customer-bg .section-text h1 {
  color: white;
}

.section-customer-bg .section-text p {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
}

.section-customer-bg .customer {
  margin-top: 15vh;
  margin-bottom: 15vh;
  background: url('../../../assets/Home/Customer/customer_list.svg');
  background-repeat: no-repeat;
  background-size: auto 180px;
  background-position: center;
  height: 180px;
  width: auto;
}

@media screen and (max-width: 322px) {
  .section-customer-bg .customer {
    margin-top: 15vh;
    margin-bottom: 15vh;
    background: url('../../../assets/Home/Customer/customer_list.svg');
    background-repeat: no-repeat;
    background-size: 80% auto;
    background-position: center;
    height: 180px;
    width: auto;
  }
}

@media screen and (min-width: 322px) and (max-width: 767px) {
  .section-customer-bg .customer {
    margin-top: 15vh;
    margin-bottom: 15vh;
    background: url('../../../assets/Home/Customer/customer_list.svg');
    background-repeat: no-repeat;
    background-size: 80% auto;
    background-position: center;
    height: 180px;
    width: auto;
  }
}